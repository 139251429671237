import React from 'react';
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselPrevious,
    CarouselNext,
    OnChangeSlideEvent,
} from '@rocco/components/carousel';

import type { CarouselOptions } from '@rocco/components/carousel';
import { ReactComponent as ArrowLeft } from '@rocco/icons/svg/arrow/arrow-chevron-left.svg';
import { ReactComponent as ArrowRight } from '@rocco/icons/svg/arrow/arrow-chevron-right.svg';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

const _carouselItemVariant = cva(
    'mr-[1rem] md:mr-[1.5rem] first:ml-0 last:mr-0 max-w-[20.25rem] md:max-w-[25.125rem]',
    {
        variants: {
            size: {
                default: 'basis-full',
                medium: 'md:max-w-[27.15rem]',
                recentlyViewed: 'md:max-w-[21.45rem]',
                suggestedCategories: 'md:max-w-[17rem]',
                mobileSearchResults: 'mr-[0.8125rem] max-w-[13.6875rem]',
                small: 'max-w-[10.75rem]',
            },
        },
        defaultVariants: {
            size: 'default',
        },
    },
);

interface CarouselSectionProps
    extends React.PropsWithChildren,
        VariantProps<typeof _carouselItemVariant> {
    title?: string;
    showArrows?: boolean;
    onChangeSlide?: (event: OnChangeSlideEvent) => void;
}

export const CarouselSection = ({
    title,
    children,
    size,
    showArrows = true,
    onChangeSlide,
}: CarouselSectionProps) => {
    const carouselOptions: CarouselOptions = {
        slidesToScroll: 1,
        breakpoints: {
            '(min-width: 768px)': {
                slidesToScroll: 4,
            },
        },
        watchDrag: (api, evt) => {
            // Only allow dragging on the main carousel element
            // Prevent dragging when interacting with child carousels
            return (
                evt.target instanceof Element &&
                !evt.target.closest('.slider-item-image')
            );
        },
    };

    return (
        <Carousel options={carouselOptions} onSettleSlide={onChangeSlide}>
            {title || showArrows ? (
                <div className="relative flex items-center justify-between mb-6 px-6 md:px-12 md:py-[0.75rem] md:mb-spacing-page-category-gap-component-s">
                    {title && (
                        <h2 className="text-xl tracking-[0.025rem] font-medium md:left-12">
                            {title}
                        </h2>
                    )}

                    <div className="flex gap-2 md:right-12 md:min-h-[2.5rem]">
                        {showArrows && (
                            <>
                                <CarouselPrevious>
                                    <ArrowLeft className="size-[1.125rem]" />
                                </CarouselPrevious>
                                <CarouselNext>
                                    <ArrowRight className="size-[1.125rem]" />
                                </CarouselNext>
                            </>
                        )}
                    </div>
                </div>
            ) : null}
            <CarouselContent variant="fullPage">
                {React.Children.map(children, child => (
                    <CarouselItem className={_carouselItemVariant({ size })}>
                        {child}
                    </CarouselItem>
                ))}
            </CarouselContent>
        </Carousel>
    );
};
